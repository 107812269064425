import {CompanyFields, OperationPermission, role} from 'types'
import AppStore from 'AppStore'

export const admin = 'admin'

export const getUserRole = (): role | undefined => {
  const roles: any[] | undefined = AppStore.keyCloak?.tokenParsed?.resource_access?.['tcare-backend'].roles.filter(
      role =>
          role === admin ||
          role === 'admin-supplier' ||
          role === 'representative' ||
          role === 'supplier' ||
          role === 'guest' ||
          role === 'viewer' ||
          role === 'viewer-supplier'
  )
  let role: role | undefined = undefined
  if (roles && roles.length > 0) role = roles[0]
  return role
}

export const isAdmin = ():boolean => {
  return getUserRole() === 'admin'
}

export const isAdminSupplier = ():boolean => {
  return getUserRole() === 'admin-supplier'
}

export const isSupplier = ():boolean => {
  return getUserRole() === 'admin-supplier' || getUserRole() === 'supplier' || getUserRole() === 'viewer-supplier'
}

export const isViewer = ():boolean => {
  return getUserRole() === 'viewer' || getUserRole() === 'viewer-supplier'
}

export const companyFieldsVisible = (companyFields: CompanyFields) => {
  const { categories, adherenceEthicsCode, companyType } = companyFields

  const representative = companyType === 'representative'
  //const representative = companyType === 'rappresentante'
  const C = categories?.find(e => e === 'PICKER') && !representative
  const H = categories?.find(e => e === 'HUNTER') && !representative
  const F = categories?.find(e => e === 'BREEDER') && !representative
  const V = categories?.find(e => e === 'VET') && !representative
  const S = categories?.find(e => e === 'SLAUGHTER') && !representative
  const T = categories?.find(e => e === 'TANNER') && !representative

  // const all = C || H || F || V || S || T
  return {
    category: !representative,
    adherenceEthicsCode: C || H || F || S || T,
    ethicsCertificate: adherenceEthicsCode && (C || H || F || S || T),
    licenseCertificate: C || H || V || S,
    membershipCertificate: F || T,
    competenceTerritory: C || H,
    healthCertificate: F || S,
    otherCertificates: true,
    allowedQuantity: H,
    suppressionMethod: F || S,
    feedingDocuments: F,
    tanneryCertifications: T,
  }
}

/*
----------------------------------------------

Possibili Ruoli di accesso :
Admin -> A
Admin Fornitore -> AF
Admin Rappresentante -> R
Fornitore -> F


---------------    TRACCE    ----------------

* Visualizzazione complessiva -> (tutti)
* Visualizzazione dettaglio traccia -> (tutti)
* Creazione traccia
  - personale -> (F,AF)
  - conto terzi -> (F,AF,R)


--------------    ONBOARDING    --------------

(A)
* Visualizzazione complessiva aziende da confermare
* Conferma nuove azienda

(AF,R)
* Visualizzazione complessiva aziende invitate e confermate

(A,AF,R)
* Invito nuova azienda
* Visualizzazione dettagli azienda


------------    REGISTRAZIONE    --------------

Segue immediatamente alla fase di Onboarding, dopo che l'azienda è stata invitata,
questa dovrà inserire i propri dati nel form a 3 step.

* Prima registrazione a 3 fasi


-----------    SUB FORNITORI    -------------

(AF,R)
* Visualizzazione complessiva
* Visualizzazione dettaglio subfornitore
* Creazione subfornitore
* Modifica subfornitore
* Sospensione subfornitore


----------    GESTIONE AZIENDA    ----------

(AF)
* Visualizzazione Utenti (che sono i fornitori)
* Creazione, modifica e cancellazione di Utenti
* Modifica del profilo aziendale

(R)
* Visualizzazione dei propri dati personali

(AF,R)
* Visualizzazione del profilo azienda


----------    GESTIONE AZIENDE    ----------

(A)
* Visualizzazione aziende (attivi,in scadenza,sospesi)
* Visualizzazione del profilo azienda
* Sospensione di un azienda

-----------    GESTIONE UTENTI    -----------

(A)
* Visualizzazione Utenti (attivi,sospesi)
* Visualizzazione dell'utente
* Sospensione di un utente
* Attivazione di un utente

----------------------------------------------

*/

export const isUserConfirmed = (): boolean => {
  return (!isSupplier() && AppStore.loggedUser?.enabled) //admin e viewer abilitati
      ||
      (!!AppStore.loggedUser?.company?.companyState && AppStore.loggedUser?.enabled  && !!AppStore.loggedUser?.company?.enabled); //altri abilitati
}

export const getOperationsForRole = (): OperationPermission => {
  const role = getUserRole()
  const A = role === admin
  const R = role === 'representative'
  const S = role === 'supplier'
  const VS = role === 'viewer-supplier'
  const AS = role === 'admin-supplier'
  const G = role === 'guest'
  const userConfirmed = isUserConfirmed()

  const SUPPLIER = S || AS || VS
  const ALL = A || R || S || AS

  const cIndex = AppStore.loggedUser?.company?.categories?.indexOf("CUSTOMER") ?? -1;
  const isCustomerSupplier = cIndex >= 0

  const rmsIndex = AppStore.loggedUser?.company?.categories?.indexOf("RAW_MATERIAL_SOURCE") ?? -1;
  const isCustomerCompany = rmsIndex >= 0

  const categoriesCount = AppStore.loggedUser?.company?.categories?.find(c => c !== "RAW_MATERIAL_SOURCE" && c !== "CUSTOMER")?.length ?? 0;

  return {
    // REGISTRAZIONE
    registration: G,

    // TRACCE
    traceReport: (ALL && userConfirmed) || A,
    traceDetail: ALL,
    traceCreatePersonal: (S || AS) && userConfirmed,
    traceCreateThirdParts: (S || AS || R) && userConfirmed,

    // ONBOARDING
    onboardingReport: A || ((AS || R) && userConfirmed && AppStore.appSettings.enableCompanyOnboarding),
    onboardingReportToConfirm: A,
    onboardingConfirmCompany: A,
    onboardingReportAll: AS || R,
    onboardingInviteCompany: A || AS || R,
    onboardingCompanyDetails: A || AS || R,

    // ORDINI
    ordersReport: (SUPPLIER && userConfirmed && categoriesCount > 0) || A,
    customerOrdersReport: (SUPPLIER && userConfirmed && isCustomerSupplier),
    rawMaterialSourceOrdersReport: (SUPPLIER && userConfirmed && isCustomerCompany),

    // SUB FORNITORI
    subsuppliersReport: (AS || R) && userConfirmed,
    subsupplierDetails: AS || R,
    subsupplierCreate: AS || R,
    subsupplierEdit: AS || R,
    subsupplierSuspend: AS || R,

    // GESTIONE AZIENDA
    companyReport: AS || R,
    userCreate: AS,
    companyPersonalDetails: R,
    companyDetailsEdit: AS,
    companyDetails: AS || R || A,

    // GESTIONE AZIENDE
    companiesReport: A || ((AS || R) && userConfirmed && AppStore.appSettings.enableCompanySuppliersManagement && categoriesCount > 0), //enableCompanySuppliersManagement
    companySuspend: A,



    // GESTIONE UTENTI
    userReport: A,
    userDetail: A || AS,
    userEdit: A,
    userSuspend: A,
    userActivate: A,
    userProfileManagment: S,

    // TODO REMOVE FALSE
    formBuilder: false,

    canConfirmCompany: A,
  }
}
