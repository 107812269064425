import React, {useEffect, useState} from 'react'
import {Redirect, Route, Router, Switch} from 'react-router-dom'
import Keycloak from 'keycloak-js'
import AppStore from 'AppStore'
import {getQueryParams, history} from 'router'
import {getPublicRoutes} from 'routes'
import 'assets/css/antd-custom.less'
import 'assets/css/app.less'
import {hot} from 'react-hot-loader/root'
import {getUserRole} from 'helpers/permission'
import {Auth} from 'api'
import api, { setOffGlobalSpinningOnHeader } from 'api/api';
import {clearInterval, setInterval} from "timers";
import Notifications from "./api/Notifications";
import errorPrint from "./tools/ErrorPrint";
import AppSettings from "./api/AppSetting";
import { showError } from './helpers/Notifications';

/**
 * Will get the url based on environment
 */
let getKeycloakUrl = async () => {
  const settings = await AppSettings.getSettings();
  return settings.ssoUrl
}

let keycloak: Keycloak.KeycloakInstance

const App: React.FC = () => {
  const [userNotFound, setUserNotFound] = useState(false)
  const [loading, setLoading] = useState(true)
  const [authenticated, setAuthenticated] = useState(false)
  const [redirectToRegistration, setRedirectToRegistration] = useState(false)

  const getNotificationFromDB = async (firstCall: boolean) => {
    let lastTimeStamp
    if (AppStore.notificationsAndCount) {
      lastTimeStamp = AppStore.notificationsAndCount.timstamp
    } else {
      lastTimeStamp = null
    }

    const axiosConfig = setOffGlobalSpinningOnHeader();
    const res = await Notifications.getNumberOfNotReadAndNews(lastTimeStamp, axiosConfig)
    if (res) {
      AppStore.setNotificationsCounter(res, firstCall)
    }
  };




  useEffect(() => {
    async function init() {

      const tenantFromQueryParam = getQueryParams().tenant

      let tenant = await AppStore.getTenantFromStorage()
      // Lato Client ha più priorità il tenant passato dal queryParam
      tenant = await AppSettings.getTenant(!!tenantFromQueryParam ? tenantFromQueryParam : tenant);
      if (!!tenant && tenant.length > 30) {
        showError("Tenant selection error", "Tenant too much long (max 30 char)");
        return;
      }

      await AppStore.setTenant(tenant);
      keycloak = Keycloak({
        url: await getKeycloakUrl(),
        clientId: 'tcare',
        realm: tenant,
      });
      api.setHeader('tenant', tenant)

      await authenticate()
      try {
        if (keycloak.tokenParsed && keycloak.refreshTokenParsed) {
          await AppStore.loadInitalData(keycloak)
          await getNotificationFromDB(true)
          if (getUserRole() === 'admin-supplier' &&
              (AppStore.loggedUser?.company?.companyState === 'WAITING_TO_CONFIRM' ||
              AppStore.loggedUser?.company?.companyState === 'NOT_CONFIRMED')) {
            setRedirectToRegistration(true)
          }
          setLoading(false)
          const timer = setInterval(getNotificationFromDB, 10000);
          return () => clearInterval(timer);
        }
      } catch (error) {
        setUserNotFound(true)
        setLoading(false)
      }

    }
    init()
  }, [])

  const authenticate = async () => {
    try {
      let auth = await keycloak.init({onLoad: 'login-required', checkLoginIframe: false})
      setAuthenticated(auth)
      setTimeout(() => {
        keycloak.onTokenExpired = () => {
          keycloak
            .updateToken(120)
            .then(function (refreshed) {
              if (refreshed) {
                keycloak.token && Auth.setHeaderAccessToken(keycloak.token)
              } else {
                console.log('token is still valid')
              }
            })
            .catch(() => {
              console.log('update token error')
            })
        }
        keycloak
          .updateToken(keycloak.tokenParsed?.exp!)
          .then(function(refreshed) {
            if (refreshed) {
              keycloak.token && Auth.setHeaderAccessToken(keycloak.token)
            } else {
              console.log('token is still valid')
            }
          })
          .catch(() => {
            console.log('update token error')
          })
      }, 30000)
    } catch (e) {
      e && errorPrint.printMessage(e)
    }
  }

  return (
    <div className="container">

      {
        userNotFound && !loading && (
          <>
            <Router history={history}>
              <Route
                path={'/userNotFound'}
                component={require('pages/users/UserNotFound').default}
                key={'/registration'}
                exact={true}
              />
                    <Redirect to="/userNotFound"/>
            </Router>
          </>

        ) ||

        authenticated && !loading && (
          <>
            <Router history={history}>
              <>
                {!redirectToRegistration && (
                  <>
                    <Switch>
                      {Object.entries<{ component: any }>(getPublicRoutes()).map(([path, route]) => (
                        <Route path={path} component={route.component} key={path}
                                         exact={true}/>
                      ))}
                    </Switch>
                            {AppStore.goToDashboard && <Redirect to="/"/>}
                  </>
                )}
                {redirectToRegistration && (
                  <>
                    <Route
                      path={'/registration'}
                      component={require('pages/registration/Registration').default}
                      key={'/registration'}
                      exact={true}
                    />
                            <Redirect to="/registration"/>
                  </>
                )}
              </>
            </Router>
          </>
        )}
    </div>
  )
}

export default hot(App)
